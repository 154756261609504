import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';

const API_BASE_URL = `${environment.apiUrl}`;
const API_USERS_URL = `${environment.apiUrl}/auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  // login(email: string, password: string): Observable<any> {
  //   return this.http.post<AuthModel>(`${API_USERS_URL}/login`, { email, password });
  // }

  /* Login method with actual access to API */
  login(email: string, password: string): Observable<any> {
    let res = this.http.post<AuthModel>(`${API_USERS_URL}/login.php`, { "email": email, "password": password});
    res.subscribe(data => {console.log(data)} );
    return res;
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  getUserByToken(token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}/me.php`, {
      headers: httpHeaders,
    });


    // const httpHeaders = {
    //   Authorization: `Bearer test123`,
    //   'Accept': 'application/json',
    //   'Access-Control-Allow-Headers': 'Content-Type',
    // }
    // const requestOptions = { 
    //   headers: new HttpHeaders(httpHeaders), 
    // };
    // return this.http.get<UserModel>(`${API_USERS_URL}/me.php`, requestOptions);
  }

  // ping(): Observable<AuthModel>{
  ping(): Observable<AuthModel> {
    return this.http.get<AuthModel>(`${API_BASE_URL}/ping.php`);
  }
}
